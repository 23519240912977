import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button, Carousel, Dropdown, Modal, Form } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Header from "../components/Header.jsx";
import UserService from '../services/UserService';
import LoadingSpinner from "../components/LoadingSpinner.jsx";
import WalletConnect from "components/WalletConnect.tsx";

const serverConfig = require("../config/server.js");

const Dashboard = () => {

  const [token, setToken] = useState(null);
  const [logoBase64Text, setLogoBase64Text] = useState('');
  const [proofs, setProofs] = useState({});
  const [userIdentityVerified, setUserIdentityVerified] = useState(false);
  const [isChiaUser, setIsChiaUser] = useState(false);
  const [vcproofs, setVcproofs] = useState(null);
  const [fingerprint, setFingerprint] = useState('');

  const navigate = useNavigate();

  const getToken = () => {
    console.log("Has wallet-auth role : ", UserService.hasRole(["wallet-auth"]));
    if(UserService.hasRole(["wallet-auth"])) {
      setIsChiaUser(true);
    }
    let jwt = UserService.getToken();
    axios.post(serverConfig.api_base_url + "user_get_token",{jwt:jwt, domain:window.location.hostname})
    //axios.post(serverConfig.api_base_url + "user_get_token",{jwt:jwt, domain:"chia.dev.mtk.dbtez.net"})
    .then(response=>{
      console.log('user_get_token response', response);
      if(response.data.status === 200){
        sessionStorage.setItem('token', response.data.token);
        setToken(response.data.token);
      } else if(response.data.status >= 400 && response.data.status <= 499){
        alert(response.data.message);
      } else if(response.data.jwt_validation === 'fail') {
        handleLogout();
      }
    })
    .catch(err=>{
      console.log("error is",err)
      setVCResponseMsg({type:'failed', icon:'delete-icon.png', message:`Some error occured. ${err}`});
    });
  }
  const handleLogout = () => {
    UserService.doLogout();
    sessionStorage.clear();
    localStorage.clear();
  }
  const [isLoading, setIsLoading] = useState(false);
  const [isRevoking, setIsRevoking] = useState(false);

  const [walletConnected, setWalletConnected] = useState(false);
  const [vcParentCoinInfo, setVcParentCoinInfo] = useState('');

  const[activity, setActivity] = useState([]);
  const[credential, setCredential] = useState([]);
  // const[credential, setCredential] = useState([
  //   {vc_number: 'UD9YAD98YD', issue_date: '10-22-2023', expiry_date: '10-22-2024', status: 'Active'},
  //   {vc_number: 'ST4967NCRE', issue_date: '11-02-2023', expiry_date: '11-05-2025', status: 'Active'},
  //   {vc_number: 'FRE6435GTG', issue_date: '06-04-2023', expiry_date: '05-22-2024', status: 'Active'},
  //   {vc_number: 'GDF6GF56EB', issue_date: '05-11-2023', expiry_date: '03-22-2024', status: 'Active'},
  //   {vc_number: 'G4667B54V5', issue_date: '05-16-2023', expiry_date: '03-22-2024', status: 'Active'}
  // ]); 

  const [vcDetailsChanged, setVCDetailsChanged] = useState(false);
  const [kycRejected, setKycRejected] = useState(true);
  const [kycCompleted, setKycCompleted] = useState(false);
  const [launcherIdVC, setLancherIdVC] = useState(false);
  const [kycCompletedTimestamp, setKycCompletedTimestamp] = useState('');
  const [paymentCompleted, setPaymentCompleted] = useState(false);
  const [kycMessage, setKycMessage] = useState('');
  const [kycAttemptExceeded, setKycAttemptExceeded] = useState(true);
  const [vcIndex, setVCIndex] = useState(0);
  const [isMintingInProgress, setIsMintingInProgress] = useState(false);
  const [isIssuing, setIsIssuing] = useState(false);
  const [vcLauncherId, setVCLauncherId] = useState('');
  const [transactionId, setTransactionId] = useState('');
  const [providerInnerPuzHash, setProviderInnerPuzHash] = useState('');
  let innerPuzHash;
  const [credentialData, setCredentialData] = useState({});
  const [userWalletAddress, setUserWalletAddress] = useState('');
  const [innerpuzHash, setInnerpuzHash] = useState('');
  //const [credentialPPIdmData, setCredentialPPIdmData] = useState({});


  const handleSelectVC = (selectedVCIndex) => {
    setVCIndex(selectedVCIndex);
  };

  const [showViewVCModal, setShowViewVCModal] = useState(false);
  const handleCloseViewVCModal = () => setShowViewVCModal(false);
  const handleShowViewVCModal = (data) => {
    console.log(data);
    setCredentialData(data);
    //console.log(JSON.parse(data.pp_idm_data).FormDetails.Identity_Validation_and_Face_Matching.IDmission_Image_Processing.ID_Image_Processing);
    //setCredentialPPIdmData(JSON.parse(data.pp_idm_data).FormDetails.Identity_Validation_and_Face_Matching.IDmission_Image_Processing.ID_Image_Processing);
    setShowViewVCModal(true);
  }

  const [actionName, setActionName] = useState(null)
  const [showVCActionModal, setShowVCActionModal] = useState(false);
  //const [isVCRevoked, setIsVCRevoked] = useState(false);
  const handleCloseVCActionModal = () => setShowVCActionModal(false);
  const handleShowVCActionModal = (action_name, launcher_id) => {
    setActionName(action_name);
    setVCLauncherId(launcher_id);
    setShowVCActionModal(true);
  }

  const [showRevokingVCModal, setShowRevokingVCModal] = useState(false);
  const handleCloseRevokingVCModal = () => setShowRevokingVCModal(false);
  const handleShowRevokingVCModal = () => setShowRevokingVCModal(true);

  const [showReissueVCModal, setShowReissueVCModal] = useState(false);
  const handleCloseReissueVCModal = () => {
    setShowReissueVCModal(false);
    setReissueFee("");
  }
  const handleShowReissueVCModal = () => setShowReissueVCModal(true);

  const [vcResponseMsg, setVCResponseMsg] = useState({});

  let VCRevokeStatus;
  let isVCRevoked = false;

  const setVCCredential = () => {
    console.log('set_vc_details called');
    console.log(innerPuzHash, 'INNER_PUZHASH');
    axios.post(serverConfig.api_base_url + "vc_details_set", {
       token: token,
       launcher_id: launcherId,
       inner_puzhash: innerPuzHash,
       vcproofs: proofs,
       parent_coin_info: parent_coin_id
    })
    .then(response=>{
      if(response.data.status === 200)
      {
      setIsLoading(false);
      setVCDetailsChanged(!vcDetailsChanged);
      console.log('vc_details_set', response);
      }
    })

    .catch(err=>{
      console.log("error is",err)
      setVCResponseMsg({type:'failed', icon:'delete-icon.png', message:`Some error occured. ${err}`});
    }
    );
  }

  /*** Verify Ownership */

  const verifyOwnership = () => {

    const options = {
      method: 'POST',
      url: serverConfig.fapi_base_url + "ownership_verify",
      headers: {
          'content-type': 'application/json'
      },
      data: 
        {
            pubkey : "87626d57507cbff0c05a825376a63577860adeca7a90779ffc541ec62eb3b2021deda5b3b645a866c14c088dde195fd5",
            signature : "86080d09e037c86498a87b7112d17563855e9d6aec6654b6988c6242a6a9889f0b192c8a3e672f228a68a959fb65ce2e14532e0cf696f938d4bcf4b36b6229eb6f6b0b37aed2ac03509e558ab8655dd5a9d1472f7ad5d852cdd80c5d00a930ef",
             message : "This is a testsign in message by address dbtez"
        }
    };

    axios
      .request(options)
      .then(function (response) {
          console.log(response.data);
      })
      .catch(function (error) {
          console.error(error);
          setVCResponseMsg({type:'failed', icon:'delete-icon.png', message:`Some error occured. ${error}`});
      });
  }

  const getVCCredential = () => {
    console.log('get_vc_details called');
    axios.post(serverConfig.api_base_url + "vc_details_get", {
      token: token
    })
    .then(response=>{
      if(response.data.status === 200) {
      console.log('vc_details_get', response);
      if(response.data.items !== null) {
        console.log('vc_details_getItems', response.data.items);
        const vcData = response.data.items.filter(el => el.status !== 'Revoked');
        console.log(vcData, 'vc_data');
        console.log(response.data.items.filter(el => el.status !== 'Revoked'), 'vc_status');
        setCredential(vcData);
        console.log(vcData, 'VC_DATA');
        if(vcData && vcData.length > 0) {
            console.log(JSON.parse(vcData[0].vcproofs), 'vcproofs');
            console.log(vcData[0].launcher_id, 'IDD');
            setVCLauncherId(vcData[0].launcher_id);
            setInnerpuzHash(vcData[0].inner_puzhash);
            setVcproofs(JSON.parse(vcData[0].vcproofs));
            setVcParentCoinInfo(vcData[0].parent_coin_info);
        }
      }
      }
    })
    .catch(err=>{
      console.log("error is",err);
      setVCResponseMsg({type:'failed', icon:'delete-icon.png', message:`Some error occured. ${err}`});
    });
  }

  const changeRevokeVCStatus = () => {
    axios.post(serverConfig.api_base_url + "vc_details_revoke", {
      token: token,
      launcher_id: vcLauncherId
    })
    .then(response=>{
      console.log('vc_revoke_response', response);
      if(response.data.status === 200) {
       {(isVCRevoked === true) ? 
         setVCResponseMsg({type:'success', icon:'delete-icon.png', message:'VC has been revoked'})
         : 
         setVCResponseMsg({type:'failed', icon:'delete-icon.png', message:'cannot delete VC!!BETTER LUCK NEXT TIME!'});
       }
       handleCloseVCActionModal();
       handleCloseRevokingVCModal();
       setIsLoading(false);
       setIsRevoking(false);
       setVCDetailsChanged(!vcDetailsChanged);
      }
    })
    .catch(err=>{
      console.log("error is",err)
      setVCResponseMsg({type:'failed', icon:'delete-icon.png', message:`Some error occured. ${err}`});
    });
  }

  const getTransactionForRevokeVC = (name) => {
    axios.post(serverConfig.api_base_url + "get_transaction",{"transaction_id" : name, "chain": "mainnet"})
    .then(response=>{
      console.log('get_transaction', response);
      console.log(response.data.transaction.confirmed, 'is_confirmed');
      console.log(typeof(response.data.transaction.confirmed));
      if(response.data.transaction.confirmed === true){
        console.log("true");
        console.log("clear interval");
        VCRevokeStatus = response.data.success;
        clearInterval(interval);
        remainingStepsForRevokeVC();
        return true;
      }
      else {
        console.log("false");
        return false;
      }
    })
    .catch(err=>{
      console.log("error is",err);
      setVCResponseMsg({type:'failed', icon:'delete-icon.png', message:`Some error occured. ${err}`});
    });
  }

  const remainingStepsForRevokeVC = () => {
    if(VCRevokeStatus === true){
      axios.post(serverConfig.api_base_url + "vc_get",{
        "vc_id" : vcLauncherId
      })
      .then(responseVCGet=>{
          console.log('vc_get_response', responseVCGet);
          if(responseVCGet.data.vc_record === null){
             changeRevokeVCStatus();
             //setVCDetailsChanged(!vcDetailsChanged);
             isVCRevoked = true;
          }
      })
      .catch(err=>{console.log("error is",err)
      setVCResponseMsg({type:'failed', icon:'delete-icon.png', message:`Some error occured. ${err}`});
    });
    }
  }

  const revokeVC = () => {
    //setIsLoading(true);
    setIsRevoking(true);
    handleCloseVCActionModal();
    handleShowRevokingVCModal();
        
    console.log(vcParentCoinInfo, 'vc_parent_coin_info');

          axios.post(serverConfig.api_base_url + "vc_revoke",
                    {
                      "vc_parent_id":`0x${vcParentCoinInfo}`,
                      //"fee": 50000,
                      "chain" : "mainnet"
                    })
          .then(responseVCRevoke=>{
              console.log('vc_get_response', responseVCRevoke);
              let transactionId = responseVCRevoke?.data?.transactions[0]?.name;

              axios.post(serverConfig.api_base_url + "get_transaction",
                  {
                    "transaction_id" : transactionId,
                    "chain" : "mainnet"
                  })
              .then(responseGetTransaction=>{
                console.log('get_transaction', responseGetTransaction);
                console.log(responseGetTransaction.data.transaction.confirmed, 'is_confirmed');
                //console.log(typeof(response.data.transaction.confirmed));
                if(responseGetTransaction.data.transaction.confirmed === true){
                  console.log("true");
                  console.log("clear interval");
                  clearInterval(interval);
                  //return true;
                }
                else {
                  console.log("set interval");
                  interval = setInterval(function() {
                    getTransactionForRevokeVC(transactionId);
                  }, 3000);
                  //return false;
                }
              })
              .catch(err=>{
                console.log("error is",err);
                setVCResponseMsg({type:'failed', icon:'delete-icon.png', message:`Some error occured. ${err}`});
              });
            })
  }

  const getKycStatus = () => {
    axios.post(serverConfig.api_base_url + "kyc_status_get",{token: token})
    .then(response=>{
      console.log('kyc_status_get kyc_status_get', response);
      if(response.data.status === 200) {
        if(response.data.kyc_completed === 1){
          setKycCompleted(true);
          setKycCompletedTimestamp(response.data.kyc_completed_timestamp);

          //check if rejected
          if(response.data.kyc_rejected === 1){
            setKycRejected(true);
            setKycMessage(response.data.kyc_rejected_message && <div style={{"textAlign" : "left"}} dangerouslySetInnerHTML={{__html: response.data.kyc_rejected_message}} />);
          } else {
            setKycRejected(false);
          }
        } else {
          setKycCompleted(false);
          setKycCompletedTimestamp('');
          setKycMessage("Click on Complete KYC to start the KYC process");
        }

        if(response.data.kyc_attempt_exceeded === 1){
          setKycAttemptExceeded(true);
        } else {
          setKycAttemptExceeded(false);
        }
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        alert(response.data.message);
      }
    })
    .catch(err=>{
      console.log("error is",err);
      setVCResponseMsg({type:'failed', icon:'delete-icon.png', message:`Some error occured. ${err}`});
    });
  }

  useEffect(() => {
    console.log(sessionStorage.getItem('token'));
    if(sessionStorage.getItem('token') === null){
      getToken();
    } else {
      setToken(sessionStorage.getItem('token'));
    }
    axios.post(serverConfig.api_base_url + "get_logo_image",{domainName:window.location.host})
    //axios.post(serverConfig.api_base_url + "get_logo_image",{domainName:"chia.dev.mtk.dbtez.net"})
    .then(response=>{
      console.log('get_logo_image response', response);
      if(response.data.status === 200) {
        setLogoBase64Text(response.data.logoBase64);
      } else if(response.data.status >= 400 && response.data.status <= 499){
        alert(response.data.message);
      }
    })
    .catch(err=>{
      console.log("error is",err);
      setVCResponseMsg({type:'failed', icon:'delete-icon.png', message:`Some error occured. ${err}`});
    });
    verifyOwnership();
  }, []);

  useEffect(() => {
    if(token !== null){
      getVCCredential();
      getKycStatus();
    }
  },[token, vcDetailsChanged]);

  let rootProofs;

  useEffect(() => {
    console.log(kycCompleted);
    if(token !== null){
      if(kycCompleted && !kycRejected){
        axios.post(serverConfig.api_base_url + "proofs_list_get",{token:token})
        .then(response=>{
          console.log('proofs_list_get response', response);
          if(response.data.status === 200) {
            if(response.data.items !== null && response.data.proof_id !== null){
              let date = Date.now().toString();
              let items = response.data.items;
              let proofid = response.data.proof_id + date;
              console.log(items);
              console.log({...items, id:proofid}, 'PROOFSSSS');
              setProofs({...items, id:proofid});
              rootProofs = {...items, id:proofid}
            }
          } else if(response.data.status >= 400 && response.data.status <= 499){
            alert(response.data.message);
          }
        })
        .catch(err=>{
          console.log("error is",err);
          setVCResponseMsg({type:'failed', icon:'delete-icon.png', message:`Some error occured. ${err}`});
        });
      }
    }
  },[token, kycCompleted, kycRejected]);

  const [privilegeSubmitData, setPrivilegeSubmitData] = useState(false);
  useEffect(() => {
    if(token !== null){
      //submit_data - is customer
      axios.post(serverConfig.api_base_url + "check_user_privilege",{token: token, privilege: "submit_data"}).then(response=>{
        console.log('check_user_privilege response', response);
        if(response.data.status === 200) {
          if(response.data.is_privileged === 1){
            setPrivilegeSubmitData(true);
          } else if(response.data.is_privileged === 0) {
            setPrivilegeSubmitData(false);
            //application_list_read - is admin / banker
            axios.post(serverConfig.api_base_url + "check_user_privilege",{token: token, privilege: "application_list_read"}).then(response=>{
              console.log('check_user_privilege response', response);
              if(response.data.status === 200) {
                if(response.data.is_privileged === 1){
                  navigate("/applications");
                } else if(response.data.is_privileged === 0) {
                  handleLogout();
                }
              } else if(response.data.status === 301){
                handleLogout();
              } else {
                alert(response.data.message);
              }
            })
            .catch(err=>console.log("error is",err));
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        }
      })
      .catch(err=>console.log("error is",err));
    }
  },[token]);

  useEffect(() => {
    if(token !== null){
      axios.post(serverConfig.api_base_url + "payment_status_get",{token: token})
      .then(response=>{
        console.log('payment_status_get', response);
        if(response.data.status === 200) {
          if(response.data.items !== null){
            if(response.data.items.isCompleted === 1){
              setPaymentCompleted(true);
            } else {
              setPaymentCompleted(false);
            }
          } else {
            setPaymentCompleted(false);
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        }
      })
      .catch(err=>{
        console.log("error is",err);
        setVCResponseMsg({type:'failed', icon:'delete-icon.png', message:`Some error occured. ${err}`});
      });

      axios.post(serverConfig.api_base_url + "customer_activity_get",{token: token})
      .then(response=>{
        console.log('customer_activity_get', response);
        if(response.data.status === 200) {
          if(response.data.items !== null){
            setActivity(response.data.items.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at)));
          } else {
            setPaymentCompleted(false);
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        }
      })
      .catch(err=>console.log("error is",err));
    }
  },[token]);

  const handleRevokeVC = () => {
    revokeVC();
  }

  const handleRenewVC = () => {
    setVCResponseMsg({type:'success', icon:'wavy-check-icon.png', message:'VC is successfully renewed'});
    handleCloseVCActionModal();
  }
  const handleReissueVC = () => {
    setVCResponseMsg({type:'success', icon:'wavy-check-icon.png', message:'VC has been reissued'});
    handleCloseVCActionModal();
  }
  const handleCloseRespMsg = () => setVCResponseMsg({});

  const [reissueFee, setReissueFee] = useState("");
  const handleSelectReissueFee = (event) => {
    setReissueFee(event.target.value)
  }

  const VCActionContent = (props) => {
    return (<>
      <Modal.Body>
        <div className="text-center">
          <div className="mb-3"><img src={require(`./../assets/images/${props.icon}`)} alt={props.iconName} /></div>
          <h6><b>{props.question}</b></h6>
          {props.text && <p>{props.text}</p>}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="transparent" onClick={handleCloseVCActionModal}>Cancel</Button>
        <Button className="loader-button" variant="primary" /* disabled={isLoading} */ onClick={props.onClick}>
          {props.buttonText}
          {/* {isLoading ? <LoadingSpinner /> : <></>} */}
          </Button>
      </Modal.Footer>
    </>);
  }

  let vcActionModalContent;
  if (actionName === 'Revoke') {
    vcActionModalContent = <VCActionContent onClick={handleRevokeVC} question="Are you sure you want to revoke VC" text={<>This can take few minutes to complete.<br/>This action is non-reversible.</>} buttonText="Revoke" icon="revoke-icon.png" iconName="Revoke icon" />;
  } else if (actionName === 'Renew') {
    vcActionModalContent = <VCActionContent onClick={handleRenewVC} question="Are you sure you want to renew VC" buttonText="Renew" icon="renew-icon.png" iconName="Renew icon" />;
  } else if (actionName === 'Reissue') {
    vcActionModalContent = <VCActionContent onClick={handleReissueVC} question="Are you sure you want to reissue VC" buttonText="Reissue" icon="revoke-icon.png" iconName="Revoke icon" />;
  }
  
  let interval;
  const getTransaction = async (name) => {
    try {
      const response = await axios.post(serverConfig.api_base_url + "get_transaction", {
        "transaction_id" : name,
        "chain": "mainnet"
      } );
      console.log('get_transaction', response);
      console.log(response.data.transaction.confirmed, 'is_confirmed');
      console.log(typeof(response.data.transaction.confirmed));
      if(response.data.transaction.confirmed === true){
        console.log("true");
        console.log("clear interval");
        clearInterval(interval);
        const rs = await remainingSteps();
        return true;
      }
      else {
        console.log("false");
        return false;
      }
    }
    catch(err) {
      console.log("error is",err);
    }
  };

  let parent_coin_id;

  const settingVCDetails = () => {

    axios.post(serverConfig.api_base_url + "get_coin_records_by_parent_ids",
      {
        "include_spent_coins": true,
        "parent_ids": [`${parentCoinInfo}`], 
        "chain" : "fn.mainnet"
      }).then(response=>{
      //debugger;
      console.log(response.data, 'response');
      console.log(response?.data?.coin_records, 'coin_records_array');
      console.log(response?.data?.coin_records[0]?.coin, 'coin_object');

        const coin_object = response?.data?.coin_records[0]?.coin;
        axios.post(serverConfig.fapi_base_url + "coin_sha256", {
          coin_object,
        }).then(response=>{

          console.log(response?.data?.result, 'hash result');
          parent_coin_id = response?.data?.result;
          setVCCredential();
          setVCDetailsChanged(!vcDetailsChanged);
          setIsMintingInProgress(false);

        }).catch(err=>{
          console.log(err, 'error');
          setVCResponseMsg({type:'failed', icon:'delete-icon.png', message:`Some error occured. ${err}`});
        })

    }).catch(err=>{
      console.log(err, 'error');
      setVCResponseMsg({type:'failed', icon:'delete-icon.png', message:`Some error occured. ${err}`});
    })
  };

  const getTransactionAfterMintDone = (name) => {
    axios.post(serverConfig.api_base_url + "get_transaction",{"transaction_id" : name, "chain": "mainnet"})
    .then(response=>{
      console.log('get_transaction', response);
      console.log(response.data.transaction.confirmed, 'is_confirmed');
      console.log(typeof(response.data.transaction.confirmed));
      if(response.data.transaction.confirmed === true){
        console.log("true");
        console.log("clear interval");
        clearInterval(interval);
        axios.post(serverConfig.api_base_url + "vc_get",{"vc_id": vcLauncherId, "chain": "mainnet"})
        .then(responseVCget=>{
          console.log('vc_get response', responseVCget);
          if(responseVCget.data.success === true) {
            console.log(responseVCget.data.vc_record);
            if(responseVCget.data.vc_record === null) {
              setIsIssuing(false);
              //alert('Credential transferred to user\'s wallet');
              setVCResponseMsg({type:'success', icon:'delete-icon.png', message:'Credential transferred to user\'s wallet'})
            }         
          }}).catch(err=>{
            console.log('err', err);
            setVCResponseMsg({type:'failed', icon:'delete-icon.png', message:'Some error occured'});
          });
        //settingVCDetails();
        return true;
      }
      else {
        console.log("false");
        return false;
      }
    })
    .catch(err=>{
      console.log("error is",err);
      setVCResponseMsg({type:'failed', icon:'delete-icon.png', message:`Some error occured. ${err}`});
    });
  }

  const getTransactionAfterVCSpend = (name) => {
    axios.post(serverConfig.api_base_url + "get_transaction",{"transaction_id" : name, "chain": "mainnet"})
    .then(response=>{
      //console.log('get_transaction', response);
      console.log(response.data.transaction.confirmed, 'is_confirmed');
      //console.log(typeof(response.data.transaction.confirmed));
      if(response.data.transaction.confirmed === true){
        console.log("true");
        console.log("clear interval");
        clearInterval(interval);
        handleVCGet();
        return true;
      }
      else {
        //console.log("false");
        return false;
      }
    })
    .catch(err=>{
      console.log("error is",err)
      setVCResponseMsg({type:'failed', icon:'delete-icon.png', message:`Some error occured. ${err}`});
    });
  }

  const handleVCSpend = () => {
    setIsIssuing(true);
    axios.post(serverConfig.fapi_base_url + "bech32_decode", {
      "address" : userWalletAddress
    }).then(response=>{
      axios.post(serverConfig.api_base_url + "vc_spend",{ 
        "vc_id": vcLauncherId,
        "new_puzhash": '0x' + response.data.result, 
        "provider_inner_puzhash": innerpuzHash,
        "chain": "mainnet",
        //"fee": 50000,
       }) //+2
      .then(responseVCSpend=>{
        console.log('vc_spend response', responseVCSpend);
        if(responseVCSpend.data.success === true) {
          console.log('name', responseVCSpend.data.transactions[0].name);
          //setTransactionId(responseVCSpend.data.transactions[0].name);
          axios.post(serverConfig.api_base_url + "get_transaction",{"transaction_id" : responseVCSpend.data.transactions[0].name, "chain": "mainnet"})
            .then(responseGetTransaction=>{
              console.log('get_transaction', responseGetTransaction);
              console.log(responseGetTransaction.data.transaction.confirmed, 'is_confirmed');
              //console.log(typeof(response.data.transaction.confirmed));
              if(responseGetTransaction.data.transaction.confirmed === true){
                console.log("true");
                console.log("clear interval");
                clearInterval(interval);
                //return true;
              }
              else {
                console.log("set interval");
                interval = setInterval(function() {
                  getTransactionAfterMintDone(responseVCSpend.data.transactions[0].name);
                }, 3000);
                //return false;
              }
            })
          .catch(err=>{
            console.log("error is",err)
            setVCResponseMsg({type:'failed', icon:'delete-icon.png', message:`Some error occured. ${err}`});
          })};
      }).catch(err=>{
        console.log("error is",err);
        setVCResponseMsg({type:'failed', icon:'delete-icon.png', message:`Some error occured. ${err}`});
      });
    }).catch(err=>{console.log("error is", err);
    setVCResponseMsg({type:'failed', icon:'delete-icon.png', message:`Some error occured. ${err}`});
    });
  }

  const handleVCGet = () => {
    axios.post(serverConfig.api_base_url + "vc_get",{ "vc_id": launcherId, "chain": "mainnet"})
    .then(responseVCGet=>{
      console.log('vc_get response', responseVCGet);
      if(responseVCGet.data.success === true) {
        console.log(responseVCGet.data.vc_record.vc.coin.parent_coin_info, 'parent_coin_info');
        parentCoinInfo = responseVCGet.data.vc_record.vc.coin.parent_coin_info;
        console.log('proof hash',responseVCGet.data.vc_record.vc.proof_hash, (responseVCGet.data.vc_record.vc.proof_hash).slice(2));
        if(sha256Hash === (responseVCGet.data.vc_record.vc.proof_hash).slice(2))
        {
          axios.post(serverConfig.api_base_url + "get_coin_records_by_parent_ids" , {
            "include_spent_coins": true,
            "parent_ids": [`${parentCoinInfo}`], 
            "chain" : "fn.mainnet"
          }).then(responseCoinRecords=> {
            console.log(responseCoinRecords.data.coin_records[0].coin, 'response_coin_records');
            if(responseCoinRecords.data.success = true) {
            const coin_object = responseCoinRecords.data.coin_records[0].coin;
            axios.post(serverConfig.fapi_base_url + "coin_sha256" , {
              "parent_id" : responseCoinRecords.data.coin_records[0].coin.parent_coin_info,
              "amount" : responseCoinRecords.data.coin_records[0].coin.amount,
              "puzzle_hash" : responseCoinRecords.data.coin_records[0].coin.puzzle_hash
            }).then(responseSHA256=>{
              console.log(responseSHA256.data.result, 'sha256 hash coin')
              if(responseSHA256.data.message === 'success');
              parent_coin_id = responseSHA256.data.result;
              setVCCredential();
              //setVCDetailsChanged(!vcDetailsChanged);
              setIsMintingInProgress(false);
              setVCResponseMsg({type:'success', icon:'delete-icon.png', message:'VC Mint Successful'})
              //alert('VC MINT SUCCESSFUL');
              //debugger;
            }).catch(err=>{
              console.log(err, 'error');
              setVCResponseMsg({type:'failed', icon:'delete-icon.png', message:'Some error occured'});
            })
            }
          }).catch(err=>{
            console.log(err, "error");
            setVCResponseMsg({type:'failed', icon:'delete-icon.png', message:`Some error occured. ${err}`});
          })
          setIsMintingInProgress(false);
          //settingVCDetails();
          // alert('VC MINT SUCCESSFUL');
          // handleVCSpend();
          // setVCCredential();
          // setVCDetailsChanged(!vcDetailsChanged);
          // setIsMintingInProgress(false);
        }
      } else {
        alert(responseVCGet.data.error);
      }
    })
    .catch(err=>{console.log("error is",err);
    setVCResponseMsg({type:'failed', icon:'delete-icon.png', message:`Some error occured. ${err}`});});
  }

  let launcherId;
  let parentCoinInfo;

  const id = Date.now().toString();

  let sha256Hash;

  //let proofsForVC = '{"criminal_charges":"false","id":"MonteiroKA5120170002039India1695208432293","pep":"false","terrorist":"false","us_citizen":"false"}';
  let proofsForVC;

  const remainingSteps = async () => {
// console.log(response.data.vc_record.vc.launcher_id);
    // let launcher_id = response.data.vc_record.vc.launcher_id;
    //vc_get
    try {
      const responseVCget = await axios.post(serverConfig.api_base_url + "vc_get",{
        "vc_id": launcherId,
        "chain": "mainnet"
      });
      console.log('vc_get response', responseVCget);
      if(responseVCget.data.success !== true) {
        return;
      }
      console.log(responseVCget.data.vc_record.vc.coin.parent_coin_info);
      setVcParentCoinInfo(responseVCget.data.vc_record.vc.coin.parent_coin_info);
      parentCoinInfo = responseVCget.data.vc_record.vc.coin.parent_coin_info;
      //vc_add_proofs
      const responseVCAddProofs = await axios.post(serverConfig.api_base_url + "vc_add_proofs",{"proofs": proofs, "chain": "mainnet"})   // need to get manatoko proofs dynamically
      console.log('vc_add_proofs response', responseVCAddProofs);
      if (responseVCAddProofs.data.success !== true) {
        throw responseVCAddProofs.data.error;
      }
      const response = await axios.post(serverConfig.fapi_base_url + "merkle_root_hash_calculate", proofs);
      console.log("merkle_root_response", response.data.result);
      sha256Hash = response.data.result;
      //vc_get_proofs_for_root
      const responseVCGetProofs = await axios.post(serverConfig.api_base_url + "vc_get_proofs_for_root", {
        "root": response.data.result,
        "chain": "mainnet"
      });
      //get root hash dynamically from the function above
      console.log('vc_get_proofs_for_root response', responseVCGetProofs);
      if (responseVCGetProofs.data.success !== true) {
        throw responseVCGetProofs.data.error;
      }
      console.log(JSON.stringify(responseVCGetProofs.data.proofs), '1st result');
      console.log(JSON.stringify(proofs), "2nd result");
      const sortedProofs = Object.keys(proofs).sort().reduce(
        (obj, key) => { 
          obj[key] = proofs[key]; 
          return obj;
        }, 
        {}
      );
      console.log(sortedProofs, 'SORTED_PROOFS');
      console.log(JSON.stringify(responseVCGetProofs.data.proofs) === JSON.stringify(sortedProofs), 'RESULT');
      if (JSON.stringify(responseVCGetProofs.data.proofs) === JSON.stringify(sortedProofs)){   // +1
        proofsForVC = JSON.stringify(responseVCGetProofs.data.proofs)
          //did_get_current_coin_info
        const responseGetCurrentInfo = await axios.post(serverConfig.api_base_url + "did_get_current_coin_info", {
          "wallet_id": 2,
          "chain": "mainnet"
        });
        console.log('did_get_current_coin_info response', responseGetCurrentInfo);
        if(responseGetCurrentInfo.data.success !== true) {
          throw responseGetCurrentInfo.data.error;
        }
        innerPuzHash = responseGetCurrentInfo.data.did_innerpuz;
        setProviderInnerPuzHash(responseGetCurrentInfo.data.did_innerpuz);
        console.log('did_innerpuz', responseGetCurrentInfo.data.did_innerpuz);
        //vc_spend
        const responseVCSpend = await axios.post(serverConfig.api_base_url + "vc_spend", {
          "vc_id": launcherId,
          "new_proof_hash": sha256Hash,
          "provider_inner_puzhash": responseGetCurrentInfo.data.did_innerpuz,
          //"fee": 50000,
          "chain": "mainnet"
        }); //+2

        console.log('vc_spend response', responseVCSpend);
        if (responseVCSpend?.data?.success !== true) {
          throw responseVCSpend.data.error;
        }
        console.log('name', responseVCSpend.data.transactions[0].name);
        setTransactionId(responseVCSpend.data.transactions[0].name);
        const responseGetTransaction = await axios.post(serverConfig.api_base_url + "get_transaction", {
          "transaction_id" : responseVCSpend.data.transactions[0].name,
          "chain": "mainnet"
        });
        console.log('get_transaction', responseGetTransaction);
        console.log(responseGetTransaction?.data?.transaction?.confirmed, 'is_confirmed');
        //console.log(typeof(response.data.transaction.confirmed));
        if(responseGetTransaction?.data?.transaction?.confirmed === true){
          console.log("true");
          console.log("clear interval");
          clearInterval(interval);
          //return true;
        }
        else {
          console.log("set interval");
          interval = setInterval(function() {
            getTransactionAfterVCSpend(responseVCSpend.data.transactions[0].name);
          }, 3000);
          //return false;
        }
      }
    } catch(err) {
      alert(err);
    }
  }

  //****remove this function */
  const handleMintVC2 = () => {}

  const handleMintVC = async () => {

  //******* hashing - start */
    console.log("hash_of_proofs");

  //********** hashing - end */
    
    //did_get_id(wallet_id:1, new_address:false) { return my_did } --> pass in did below

    try {
      const responseDID = await axios.post(serverConfig.api_base_url + "did_get_did", {
        "wallet_id":2,
        "new_address":0,
        "chain": "mainnet",
      });

      setIsMintingInProgress(true);
      console.log('did_get_id_response', responseDID?.data?.my_did);
      let did_id = responseDID?.data?.my_did;
         
      const responseTargetAddress = await axios.post(serverConfig.api_base_url + "get_next_address", {
        "wallet_id":1,
        "new_address":0,
        "chain": "mainnet"
      });

      console.log('target_address_response', responseTargetAddress?.data?.address);
      let target_address = responseTargetAddress?.data?.address;

      const response = await axios.post(serverConfig.api_base_url + "vc_mint", {
        "did_id": did_id,
        "target_address": target_address,
        //"fee": 50000,
        "chain": "mainnet"
      });

      console.log('vc_mint response', response);
      console.log(response.data.vc_record.vc.launcher_id);
      launcherId = response.data.vc_record.vc.launcher_id;
      setLancherIdVC(response.data.vc_record.vc.launcher_id);
      if (response.data.success !== true) {
        throw response.data.error;
      }

      console.log(response.data.transactions[0].name);

      const responseGetTransaction = await axios.post(serverConfig.api_base_url + "get_transaction", {
        "transaction_id": response.data.transactions[0].name,
        "chain": "mainnet"
      });

      console.log('get_transaction', responseGetTransaction);
      console.log(responseGetTransaction.data.transaction.confirmed, 'is_confirmed');
      //console.log(typeof(response.data.transaction.confirmed));
      if(responseGetTransaction.data.transaction.confirmed === true){
        console.log("true");
        console.log("clear interval");
        clearInterval(interval);
        //return true;
      }
      else {
        console.log("set interval");
        interval = setInterval(async () => {
          const rs = await getTransaction(response.data.transactions[0].name);
        }, 3000);
        //return false;
      }
    }
    catch (err) {
      console.error(err);
    }
  }

  useEffect(() => {
   //handleMintVC();
  },[])

  return (
    <div className="bg-color8">
      <Header isRevoking={isRevoking} isMintingInProgress={isMintingInProgress} isIssuing={isIssuing} />
      <main>
        <Container>
          <section className="d-flex justify-content-between mb-3">
            <div className="fs-18px txt-gray"><b>Welcome {UserService.getWelcomeName()}!</b></div>
            {/* <div>
              <Button variant="plain">&#43; Add Credential</Button>
            </div> */}
          </section>
          <Row className="main-wrap">
            <Col lg="4">
              <div className="box-link mb-3">
                <Row className="wallet-row">
                  <Col>
                    <img src={require('./../assets/images/chia-logo.png')} alt="Chia logo" />
                    <div className="mt-2"><b className="fw-medium">Chia Wallet</b></div>
                  </Col>
                  <Col xs="auto">
                    <div>
                      {/* {credential?.length <= 0 && walletConnected && <> <div className="connected-div">
                        <img src={require('./../assets/images/connected-icon.png')} alt="Connected icon" className="me-1" />
                        <b>Connected</b>
                      </div>
                     </>} */}
                      {walletConnected ? <div className="connected-div">
                        <img src={require('./../assets/images/connected-icon.png')} alt="Connected icon" className="me-1" />
                        <b>Connected</b>
                      </div> : <>
                        <WalletConnect setFingerprint={setFingerprint} isChiaUser={isChiaUser} userIdentityVerified={userIdentityVerified} userWalletAddress={userWalletAddress} setUserWalletAddress={setUserWalletAddress} setUserIdentityVerified={setUserIdentityVerified} setWalletConnected={setWalletConnected} walletConnected={walletConnected} proofsForVC={vcproofs} credential={credential} page="dashboard"/>
                      </>}
                    </div>
                    {walletConnected ? <div className="txt-gray">Hot Wallet ({fingerprint})</div> : <></>}
                  </Col>
                </Row>
              </div>
               <div className="box-link mb-3">
                <Row className="align-items-center">
                  <Col xs="auto">
                    <h3 className="mb-0">KYC Process</h3>
                    {kycCompleted && <div className="mt-2"><span className="txt-gray">{kycRejected ? 'Failed' : 'Completed'}:</span> {kycCompletedTimestamp}</div>}
                  </Col>
                  <Col>
                    {!kycCompleted && <div><span className="p-1 bg-color3 rounded"><b>Pending</b></span></div>}
                  </Col>
                </Row>
                {(!kycCompleted || kycRejected) && <div className="mt-2">
                  <Button variant="transparent" onClick={() => navigate("/kyc-verification")} disabled={kycAttemptExceeded}>Complete KYC</Button>
                </div>}
              </div> 
              {(kycCompleted && !kycRejected && !paymentCompleted) && <div className="mt-2 mb-3">
                <Button variant="transparent" onClick={() => navigate("/payment")}>Complete Payment</Button>
              </div>}
              {paymentCompleted && <section className="mb-2">
                {
                credential.length > 0 
                ?
                <div className="position-relative">
                  <Carousel activeIndex={vcIndex} onSelect={handleSelectVC} controls={false} interval={null}>
                    {credential.map((item, index) => {
                      return(
                        <Carousel.Item key={index}>
                          <div className="credential">
                            <div className="d-flex position-relative justify-content-between">
                              <div>
                                <img src={logoBase64Text} alt="Logo" />
                                <h6 className="mt-3">Verifiable Credential</h6>
                                <div className="txt-gray">{item.vc_number}</div>
                              </div>
                              <div>
                                <Dropdown autoClose="inside">
                                  <Dropdown.Toggle id="dropdown-basic"><img src={require('./../assets/images/dots-horizontal-icon.png')} alt="Dots" /></Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    {/* <Dropdown.Item>Download</Dropdown.Item> */}
                                    <Dropdown.Item onClick={handleShowReissueVCModal}>Reissue</Dropdown.Item>
                                    <Dropdown.Item onClick={() => handleShowVCActionModal('Revoke', item.launcher_id)}>Revoke</Dropdown.Item>
                                    <Dropdown.Item onClick={() => handleShowVCActionModal('Renew')}>Renew</Dropdown.Item>
                                    <Dropdown.Item onClick={() => handleShowViewVCModal(item)}>View Data</Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            </div>
                            <div className="position-relative mt-4 card-details">
                              <Row>
                                <Col>
                                  <h6>ISSUED</h6>
                                  <div><b className="fw-medium">{item.issue_date}</b></div>
                                </Col>
                                <Col>
                                  <h6>EXPIRES</h6>
                                  <div><b className="fw-medium">{item.expiry_date}</b></div>
                                </Col>
                                <Col xs="3">
                                  <h6>STATUS</h6>
                                  <div><b className="fw-medium">{item.status}</b></div>
                                </Col>
                              </Row>
                            </div>
                          </div>
                        </Carousel.Item>
                      )
                    })}
                  </Carousel>
                  {/* {!kycCompleted && <Col xs="auto">
                      <Link to="" className="text-decoration-none">
                        <Button className="kyc-complete-btn">Complete KYC</Button>
                      </Link>
                    </Col>} */}
                   
                   {isIssuing ? <></> :
                    <Button variant="transparent" className="cred-btn" onClick={handleVCSpend} disabled={userIdentityVerified === true ? false : true}>Issue Credential to Chia Wallet</Button> }
                    <Button className="add-btn">+ Add Credential</Button>
                  {/* <Button variant="plain" className="btn-add-credential">&#43; Add Credential</Button> */}
                </div> : <>
                  {/* <h6><b>Services</b></h6> */}
                  <Row className="services-list">
                    {/* {kycCompleted && <Col xs="auto">
                      <Link to="/kyc-verification" className="text-decoration-none">
                        <Button className="kyc-complete-btn">Complete KYC</Button>
                      </Link>
                    </Col>} */}
                    <Col xs="auto">
                    </Col>
                  </Row>
                </>}
              </section>}

              {kycMessage && <p style={{"color": "red", "textAlign" : "center"}}>{kycMessage}</p>}

              
               { (!credential.length > 0 && kycCompleted && !kycRejected && paymentCompleted) ?
               isMintingInProgress ? <></>
                        :
                        <>
                        <p style={{"color": "red", "textAlign" : "center"}}>Click on Add Credential to mint a VC</p>
                        <Button variant="transparent" className="issue-btn" onClick={handleMintVC}>Add Credential</Button>
                        </> : 
                        <>
                        
                        </> }
              

            </Col>
            <Col lg="8">
              <Row>
                <Col className="activity bg-white">
                  <h6 className="mb-3"><b>Activity</b></h6>
                  {activity.length > 0 ? <>
                    <ul className="activity-list">
                      {activity.map((item, index) => {
                        return(<li key={index}>
                          <div className="icon"><img src={require('./../assets/images/wavy-check-icon-black.png')} alt="completed-icon" /></div>
                          <div className="details">
                            <div className="d-flex justify-content-between align-items-center">
                              <h6><b>{item.title}</b></h6>
                              <span>{item.updated_at}</span>
                            </div>
                            {item.did && <div className="mt-2"><b className="fw-medium">{item.did}</b></div>}
                          </div>
                        </li>)
                      })}
                    </ul>
                  </> : <div className="text-center pt-5 pb-4">
                    <img src={require('./../assets/images/digital-currency-wallet.png')} alt="Digital currency wallet" />
                    <p className="txt-gray mt-4">No activity yet</p>
                  </div>}
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>

        {Object.keys(vcResponseMsg).length > 0 && <div className={`vc-response-msg ${vcResponseMsg.type}`}>
          <div className="icon"><img alt="" src={require(`./../assets/images/${vcResponseMsg.icon}`)} /></div>
          <span>{vcResponseMsg.message}</span>
          <Button variant="plain" onClick={handleCloseRespMsg}><img src={require('./../assets/images/close-icon-white.png')} alt="Close" /></Button>
        </div>}

        {/* View VC */}
        <Modal show={showViewVCModal} onHide={handleCloseViewVCModal} backdrop="static" keyboard={false} centered>
          <Modal.Header closeButton>
            <Modal.Title><b>Credential Data</b></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div className="d-flex justify-content-between">
                <h6 className="mb-3 txt-gray"><b>{credentialData.vc_number}</b></h6>
                <Button variant="plain">
                  <img src={require('./../assets/images/download-icon-circle.png')} alt="Download" className="me-2" />
                  <b>Download</b>
                </Button>
              </div>
              <div className="mb-3">
                <label>Country of residence</label>
                <div><b>{credentialData.country_residence}</b></div>
              </div>
              <div className="mb-3">
                <label>Nationality</label>
                <div><b>{credentialData.nationality}</b></div>
              </div>
              <div className="mb-3">
                <label>Expiry date</label>
                <div><b>{credentialData.expiry_date}</b></div>
              </div>
              <div className="mb-3">
                <label>Verifiable Credential Type</label>
                <div><b>KYC</b></div>
              </div>
              <div className="mb-3">
                <label>Credential Status</label>
                <div><b>{credentialData.status}</b></div>
              </div>
              <div className="mb-3">
                <label>Issuer DID</label>
                <div><b>did:chia:1znhjgdvhup5wx3540cemrvhxrvts3sdrhmvfvvvqmzgrkhu37dusrt84s5</b></div>
              </div>
              <div className="mb-3">
                <label>Credential ID</label>
                <div><b>{credentialData.launcher_id}</b></div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* Reissue VC */}
        <Modal show={showReissueVCModal} onHide={handleCloseReissueVCModal} backdrop="static" keyboard={false} centered>
          <Modal.Body>
            <div>
              <h6 className="text-center mb-3"><b>Reissue</b></h6>
              <Form>
                <Form.Group className="mb-4">
                  <Form.Label>Fee</Form.Label>
                  <Form.Select value={reissueFee} onChange={handleSelectReissueFee}>
                    <option value="">Select</option>
                    <option value="0.5">0.5 XCH (~5 mins)</option>
                    <option value="1.0">1.0 0XCH (~10 mins)</option>
                    <option value="1.5">1.5 XCH (~15 mins)</option>
                  </Form.Select>
                </Form.Group>
                <div className="d-flex align-items-center justify-content-between">
                  <div>Total Price:</div>
                  <div>{reissueFee !== '' && <b>{reissueFee} XCH</b>}</div>
                </div>
              </Form>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="transparent" onClick={handleCloseReissueVCModal}>Cancel</Button>
            {/* <Button variant="primary" onClick={handleReissueVC} disabled={reissueFee === ''}>Reissue</Button> */}
            <Button variant="primary" onClick={() => {handleCloseReissueVCModal(); handleShowVCActionModal('Reissue')}} disabled={reissueFee === ''}>Reissue</Button>
          </Modal.Footer>
        </Modal>

        {/* Revoke VC */}
        <Modal show={showVCActionModal} onHide={handleCloseVCActionModal} backdrop="static" keyboard={false} centered>
          {vcActionModalContent}
        </Modal>

        {/* Revoking VC */}
        <Modal show={showRevokingVCModal} onHide={handleCloseRevokingVCModal} backdrop="static" keyboard={false} centered>
          <Modal.Body>
            <div className="text-center">
              <div className="mb-3"><img src={require('./../assets/images/revoking-icon.gif')} alt="Revoking" /></div>
              <h6><b>VC revoke in progress</b></h6>
              <p>This may take a few minutes</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleCloseRevokingVCModal}>Close</Button>
          </Modal.Footer>
        </Modal>
      </main>
    </div>
  );
};

export default Dashboard;
